import React from "react"
import "./styles/Contact.scss"
import hero from "../resources/images/hero-gdpr.jpg"
import MainContent from "../components/Layout/MainContent"
import Title from "../components/Text/Title"
import BodyContent from "../components/Text/BodyContent"
import Container from "../components/Layout/Container"

function gdpr() {
  return (
    <Container page="GDPR" shareImage={hero}>
      <div className="gdpr">
        <MainContent imageInternal={hero}>
          <Title>GDPR</Title>
          <BodyContent>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mediahuis.be/nl/voorwaarden/gebruiksvoorwaarden/"
                >
                  Gebruikersvoorwaarden
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.mediahuis.be/privacy-policy"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mediahuis.be/nl/voorwaarden/cookiebeleid/"
                >
                  Cookiebeleid
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.mediahuis.be/overeenkomst-voor-gegevensgebruik"
                >
                  Overeenkomst voor gegevensgebruik
                </a>
              </li>
            </ul>
          </BodyContent>
        </MainContent>
      </div>
    </Container>
  )
}

export default gdpr
